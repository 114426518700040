// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

//for debugging to see if loaded
console.log('Vite ⚡️ Rails')

// code to setup inertia js
import '../components/Application.js'
